<template>
  <div class="">
    <v-dialog
      v-model="alisverisTamamla"
      transition="dialog-bottom-transition"
      :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
      :max-width="genislikHesap"
      scrollable
      persistent=""
      class="rounded-0"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="tertiary"
          class="black--text font-weight-bold text-capitalize"
          depressed
          :disabled="!alisverisDurum"
          large
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left>mdi-cart</v-icon> {{ $t("alisverisTamala") }}
        </v-btn>
      </template>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="pa-0">
            <v-list class="w-100">
              <v-list-item v-if="alisverisTamamlaDurum == 1">
                <v-list-item-action class="mr-5">
                  <v-btn
                    fab
                    :small="$vuetify.breakpoint.smAndDown ? true : false"
                    elevation="0"
                  >
                    <v-icon>mdi-cart</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("sepetiniz") }}
                  </v-list-item-title>

                  <v-list-item-subtitle class="text-caption  black--text">
                    {{ $t("sepetUrunSayi", { miktar: cartMiktar }) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="pr-2">
                  <v-btn
                    fab
                    small
                    elevation="0"
                    @click="alisverisTamamla = false"
                  >
                    <v-icon size="21">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-if="alisverisTamamlaDurum == 2">
                <v-list-item-action class="mr-5">
                  <v-btn
                    fab
                    :small="$vuetify.breakpoint.smAndDown ? true : false"
                    elevation="0"
                    @click="alisverisTamamlaDurum = 1"
                  >
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("taslimatPlanlama") }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="pr-2">
                  <v-btn
                    fab
                    small
                    elevation="0"
                    @click="alisverisTamamla = false"
                  >
                    <v-icon size="21">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-if="alisverisTamamlaDurum == 3">
                <v-list-item-action class="mr-5">
                  <v-btn
                    fab
                    :small="$vuetify.breakpoint.smAndDown ? true : false"
                    elevation="0"
                    @click="alisverisTamamlaDurum = 2"
                  >
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("odemeBaslik") }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="pr-2">
                  <v-btn
                    fab
                    small
                    elevation="0"
                    @click="alisverisTamamla = false"
                  >
                    <v-icon size="21">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-title>
          <v-divider />
          <v-card-text style="max-height: 900px;" class="pa-0  grey lighten-4">
            <div class="scroll">
              <v-stepper
                v-model="alisverisTamamlaDurum"
                non-linear
                vertical
                class="pa-0 rounded-0"
                style="box-shadow:none"
              >
                <v-stepper-content class="pa-0 ma-0" step="1">
                  <v-list color="grey lighten-4 pa-0">
                    <div v-for="(urun, i) in cartUrunler">
                      <v-list-item class="pa-0">
                        <v-list-item-action
                          class="mx-0 px-5"
                          color="grey lighten-2"
                        >
                          <div class="d-flex flex-column align-center">
                            <v-btn
                              class="black--text"
                              color="transparent"
                              plain
                              x-small
                              fab
                              elevation="0"
                              @click="arttir(i)"
                              style="color:#000 !important"
                            >
                              <v-icon :size="16">
                                mdi-plus-thick
                              </v-icon>
                            </v-btn>
                            <v-avatar color="white" size="30">
                              <span
                                class="black--text text-caption font-weight-bold"
                                >{{ urun.miktar }}</span
                              >
                            </v-avatar>
                            <v-btn
                              class="black--text"
                              color="transparent"
                              plain
                              x-small
                              fab
                              elevation="0"
                              @click="azalt(i)"
                              style="color:#000 !important"
                            >
                              <v-icon :size="16">
                                mdi-minus-thick
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-list-item-action>
                        <v-list-item-content class="pr-3">
                          <v-list-item-title class="">
                            <div class="text-body-1 font-weight-medium">
                              {{ sepetUrunBilgi(urun, "ad") }}
                              <span v-if="sepetUrunBilgi(urun, 'file')"></span>
                            </div>
                            <div
                              v-if="sepetUrunBilgi(urun, 'aciklama')"
                              class="text-caption"
                            >
                              {{ sepetUrunBilgi(urun, "aciklama") }}
                            </div>
                          </v-list-item-title>
                          <div v-if="urun.kisisellestirme.length > 0">
                            <!-- <div class="text-caption pt-2">
                            {{ $t("kisisellestirmeler") }}
                          </div> -->
                            <div class="d-flex flex-wrap text-normal-wrap  ">
                              <div
                                v-for="(kisisellestirme,
                                ki) in urun.kisisellestirme"
                                :key="ki"
                              >
                                <v-chip
                                  small
                                  color="grey lighten-2"
                                  label
                                  class="my-1 mr-1"
                                >
                                  {{
                                    kisisellestirmeBilgi(kisisellestirme, "ad")
                                  }}
                                  <span
                                    class="pl-1 font-weight-bold"
                                    v-if="
                                      kisisellestirmeBilgi(
                                        kisisellestirme,
                                        'ilaveFiyat'
                                      )
                                    "
                                  >
                                    +
                                    {{
                                      kisisellestirmeBilgi(
                                        kisisellestirme,
                                        "ilaveFiyat"
                                      ) | currency
                                    }}
                                  </span>
                                </v-chip>
                              </div>
                            </div>
                          </div>
                          <v-textarea
                            v-model="urun.not"
                            v-if="urun.notDurum"
                            hide-details
                            auto-grow
                            background-color="white"
                            rows="1"
                            :label="$t('aciklama')"
                            color="primary"
                            append-icon="mdi-comment"
                            solo
                            class="my-3"
                          />
                          <div
                            class="d-flex align-center justify-space-between"
                          >
                            <div class="d-flex align-center mt-3">
                              <v-chip
                                color="primary"
                                label
                                class="font-weight-medium"
                                small
                              >
                                <span v-if="!urun.kisisellestirme">{{
                                  sepetUrunBilgi(urun, "fiyat") | currency
                                }}</span>
                                <span v-else>{{
                                  sepetUrunKisisellestirme(urun) | currency
                                }}</span>
                              </v-chip>
                              <v-btn
                                depressed
                                rounded
                                small
                                plain
                                class="text-capitalize ml-2"
                                @click="
                                  cartUrunler[i].notDurum = !cartUrunler[i]
                                    .notDurum
                                "
                              >
                                <v-icon left>
                                  {{
                                    cartUrunler[i].notDurum
                                      ? "mdi-pencil-off"
                                      : "mdi-pencil"
                                  }} </v-icon
                                >{{ $t("aciklama") }}
                              </v-btn>
                            </div>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider />
                    </div>
                  </v-list>
                </v-stepper-content>

                <v-stepper-content class="pa-0 ma-0" step="2">
                  <v-card class="pa-0 grey lighten-4" flat rounded="0">
                    <v-card-text class="px-4">
                      <v-radio-group v-model="planlamaDurum" class="pa-0 ma-0">
                        <template v-slot:label>
                          <div>{{ $t("teslimatPlanlama") }}</div>
                        </template>
                        <v-radio
                          :value="1"
                          color="secondary"
                          :disabled="!magazaDurum"
                        >
                          <template v-slot:label>
                            <div>
                              {{ $t("enErkenTeslimat") }}
                            </div>
                          </template>
                        </v-radio>
                        <v-radio :value="2">
                          <template v-slot:label>
                            <div>
                              {{ $t("dahaSonraTeslimat") }}
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>

                      <planlama v-if="planlamaDurum == 2" />
                      <div v-if="musteriDurum">
                        <v-list two-line color="transparent">
                          <v-list-item class="px-0">
                            <v-list-item-icon>
                              <v-icon color="grey">
                                mdi-account
                              </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-subtitle>
                                {{ $t("adSoyad") }}
                              </v-list-item-subtitle>
                              <v-list-item-title>
                                {{ musteriBilgi.ad }}
                                {{ musteriBilgi.soyad }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider inset />

                          <v-list-item class="px-0">
                            <v-list-item-icon>
                              <v-icon color="grey">
                                mdi-phone
                              </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-subtitle>
                                {{ $t("telefon") }}</v-list-item-subtitle
                              >
                              <v-list-item-title>
                                {{ musteriBilgi.tel }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider inset />

                          <v-list-item class="px-0">
                            <v-list-item-icon>
                              <v-icon color="grey">
                                mdi-email
                              </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-subtitle>
                                {{ $t("eposta") }}
                              </v-list-item-subtitle>
                              <v-list-item-title>
                                {{ musteriBilgi.mail }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider inset />

                          <v-list-item class="px-0">
                            <v-list-item-icon>
                              <v-icon color="grey">
                                mdi-map-marker
                              </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-subtitle>
                                {{ $t("adres") }}</v-list-item-subtitle
                              >
                              <v-list-item-title>
                                {{ musteriBilgi.adres }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                      <div v-else>
                        <v-text-field
                          v-model="musteriBilgi.ad"
                          filled
                          rounded
                          background-color="grey lighten-2"
                          class="pb-6"
                          :label="$t('ad')"
                          hide-details
                          :rules="nameRules"
                          required
                        />
                        <v-text-field
                          v-model="musteriBilgi.soyad"
                          filled
                          background-color="grey lighten-2"
                          rounded
                          class="pb-6"
                          :label="$t('soyad')"
                          hide-details
                          :rules="nameRules"
                          required
                        />
                        <v-text-field
                          v-model.number="musteriBilgi.tel"
                          :label="$t('telefon')"
                          filled
                          background-color="grey lighten-2"
                          rounded
                          type="tel"
                          class="pb-6"
                          hide-details
                          :rules="nameRules"
                          required
                        />
                        <v-text-field
                          v-model="musteriBilgi.mail"
                          :label="$t('eposta')"
                          filled
                          background-color="grey lighten-2"
                          rounded
                          type="email"
                          class="pb-6"
                          required
                          :rules="emailRules"
                          hide-details=""
                        />
                        <v-chip
                          close
                          close-icon="mdi-refresh"
                          large
                          class=" pr-3 w-100 mb-6"
                          @click:close="bolgeDurum()"
                          @click="bolgeDurum()"
                        >
                          <v-chip
                            block
                            :color="renk"
                            class="mr-2 pa-2 pr-3 text-caption"
                          >
                            {{ bolgeGetir(bolge) }}
                          </v-chip>
                        </v-chip>
                        <v-textarea
                          v-if="teslimatTip == 1"
                          v-model="musteriBilgi.adres"
                          filled
                          background-color="grey lighten-2"
                          rounded
                          rows="3"
                          class="pb-6"
                          hide-details
                          :label="$t('adres')"
                          :rules="nameRules"
                          required
                        />
                      </div>
                    </v-card-text>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content class="pa-0 ma-0" step="3">
                  <v-card
                    class="rounded-lg pa-0 grey lighten-4"
                    flat
                    rounded="0"
                  >
                    <v-card-text class="pa-0">
                      <v-simple-table class=" grey lighten-4">
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td class="font-weight-medium pl-5">
                                {{ $t("sepetTutari") }}
                              </td>
                              <td class="text-right pa-0 ma-0">
                                :
                              </td>
                              <td class="text-left">
                                {{ cartToplam | currency }}
                              </td>
                            </tr>
                            <tr>
                              <td class="font-weight-medium pl-5">
                                {{ $t("teslimatUcret") }}
                              </td>
                              <td class="text-right pa-0 ma-0">
                                :
                              </td>
                              <td class="text-left">
                                {{ Number(getirUcret) | currency }}
                              </td>
                            </tr>
                            <tr>
                              <td class="font-weight-medium pl-5">
                                {{ $t("genelToplam") }}
                              </td>
                              <td class="text-right pa-0 ma-0">
                                :
                              </td>
                              <td class="text-left">
                                {{
                                  (cartToplam + Number(getirUcret)) | currency
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <v-divider />
                      <div class="white">
                        <v-textarea
                          v-model="siparisNot"
                          hide-details
                          background-color="white"
                          rows="3"
                          :label="$t('siparisNotu')"
                          color="primary"
                          append-icon="mdi-comment"
                          filled=""
                          rounded=""
                        />
                      </div>
                      <v-divider />
                      <div class="px-4">
                        <div class="font-weight-medium black--text pt-5 pb-3">
                          {{ $t("odemeTipi") }}
                        </div>
                        <v-radio-group
                          v-model="odemeTip"
                          mandatory=""
                          hide-details=""
                          class="ma-0 pa-0 pb-3"
                        >
                          <v-radio
                            v-if="teslimatTip == 1"
                            class="text-caption black--text font-weight-medium"
                            :label="$t('kapidaNakit')"
                            :value="1"
                          />
                          <v-radio
                            v-if="teslimatTip == 1"
                            class="text-caption black--text font-weight-medium"
                            :label="$t('kapidaKrediKarti')"
                            :value="2"
                          />
                          <v-radio
                            v-if="teslimatTip == 0"
                            class="text-caption black--text font-weight-medium"
                            :label="$t('mekandaOdeme')"
                            :value="3"
                          />
                        </v-radio-group>
                      </div>
                      <v-divider class="my-3" id="scrollToOdeme" />
                      <div class="px-4">
                        <v-checkbox
                          v-model="checkbox"
                          class="ozel-checkbox"
                          hide-details=""
                          ><template v-slot:label>
                            <div class="text-body-2">
                              {{ $t("siteOnay1") }}
                            </div>
                          </template>
                        </v-checkbox>
                      </div>
                      <v-divider class="my-3" />
                      <div class="px-4 pb-3">
                        <i18n path="siteKural1" class="">
                          <a
                            slot="action1"
                            @click="dialogKural1 = true"
                            class="text-caption"
                            >{{ $t("kural1") }}</a
                          >
                          <a
                            slot="action2"
                            @click="dialogKural2 = true"
                            class="text-caption"
                            >{{ $t("kural2") }}</a
                          >
                        </i18n>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-stepper-content>
              </v-stepper>
            </div>
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-btn
              v-if="alisverisTamamlaDurum == 1"
              :color="alisverisDurum ? 'secondary' : 'error'"
              depressed
              large
              block
              class="text-capitalize rounded-0"
              style="height:auto"
              @click="
                alisverisDurum
                  ? (alisverisTamamlaDurum = 2)
                  : (alisverisTamamla = false)
              "
            >
              <div class="d-flex flex-column py-3">
                <div
                  class="text-body-1 text-md-h6 pb-1"
                  style="line-height: 1;"
                >
                  <span v-if="alisverisDurum">{{ $t("alisverisTamala") }}</span>
                  <span v-else>{{ $t("alisveriseDevam") }}</span>
                </div>
                <div class="text-caption text-md-body-2">
                  <span v-if="alisverisDurum">
                    {{ $t("sepetTutari") }} {{ cartToplam | currency }}
                  </span>
                  <span v-else>
                    {{
                      $t("kalanTutar", {
                        kalan: `${(enAzSipasir - cartToplam).toFixed(2)}`
                      })
                    }}
                  </span>
                </div>
              </div>
            </v-btn>
            <v-btn
              v-if="alisverisTamamlaDurum == 2"
              :color="alisverisDurumKontrol() ? 'error' : 'secondary'"
              depressed
              large
              block
              class="text-capitalize rounded-0"
              @click="
                alisverisDurumKontrol() ? '' : (alisverisTamamlaDurum = 3)
              "
            >
              <div
                v-if="alisverisDurumKontrol()"
                class="text-body-1 text-md-h6"
                style="line-height: 1;"
              >
                {{ $t("lutfenDoldur") }}
              </div>
              <div
                v-else
                class="text-body-1 text-md-h6"
                style="line-height: 1;"
              >
                {{ $t("devam") }}
              </div>
            </v-btn>
            <v-btn
              v-if="alisverisTamamlaDurum == 3 && ayarlar.alisverisDurum"
              color="secondary"
              depressed
              large
              large
              block
              :loading="loading"
              class="text-capitalize rounded-0"
              @click="save()"
            >
              {{ $t("onay") }}
              {{ (cartToplam + Number(getirUcret)) | currency }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <div v-if="yazilar.length > 0">
      <v-dialog v-model="dialogKural1" scrollable width="600">
        <v-card>
          <v-card-title class="text-h5 ">
            <v-spacer />
            <v-btn fab small elevation="0" @click="dialogKural1 = false">
              <v-icon size="21">
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="white">
            <div class="black--text" v-html="yazilar[0].icerik"></div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogKural2" scrollable width="600">
        <v-card>
          <v-card-title class="text-h5 ">
            <v-spacer />
            <v-btn fab small elevation="0" @click="dialogKural2 = false">
              <v-icon size="21">
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="white">
            <div class="black--text" v-html="yazilar[1].icerik"></div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { O2A, FirstO2A } from "object-to-array-convert";
import store from "./../store/index";
import { veri, fonksiyon } from "./../db";
import gsap from "gsap";
import moment from "moment";
export default {
  name: "AlisverisTamamla",
  data: () => ({
    valid: true,
    dialogKural1: false,
    dialogKural2: false,
    renk: "secondary",
    checkbox: false,
    checkbox2: false,
    odemeTip: "",
    loading: false,
    yazilar: [],
    ayarlar: {
      alisverisDurum: ""
    },
    profil: {
      sirket: ""
    },
    siparisNot: "",

    musteriBilgi: {
      kod: "",
      ad: "",
      soyad: "",
      tel: "",
      mail: "",
      bolge: "",
      adres: "",
      reklam: false,
      durum: true
    },
    nameRules: [v => !!v || "Name is required"],
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
  }),
  watch: {
    cartUrunler: function(val) {
      if (val.length == 0) {
        this.alisverisTamamla = false;
      }
    },
    alisverisTamamla: function(val) {
      if (val) {
        if (this.sepetGoruntuleme) {
          this.$store.set("app/alisverisTamamlaDurum", 1);
        } else {
          this.$store.set("app/alisverisTamamlaDurum", 2);
        }
      } else {
        this.$store.set("app/sepetGoruntuleme", false);
      }
    }
  },
  firebase: {
    ayarlar: veri.ref("ayarlar"),
    profil: veri.ref("profil"),
    yazilar: veri.ref("yazilar")
  },
  created() {
    if (
      this.$cookies.get("cerezKabul") &&
      this.$cookies.get("cerezMusteriBilgi")
    ) {
      this.musteriBilgi = this.$cookies.get("cerezMusteriBilgi");
    }
    if (!this.magazaDurum) {
      this.planlamaDurum = 2;
    }
    if (this.musteriDurum) {
      this.musteriBilgi.ad = this.musteri.ad;
      this.musteriBilgi.soyad = this.musteri.soyad;
      this.musteriBilgi.tel = this.musteri.tel;
      this.musteriBilgi.mail = this.musteri.mail;
      this.musteriBilgi.adres = this.musteri.adres;
    }
  },
  computed: {
    ...sync("app", [
      "alisverisDurum",
      "bolgeler",
      "bolge",
      "musteri",
      "musteriDurum",
      "alisverisTamamla",
      "alisverisTamamlaDurum",
      "sepetGoruntuleme",
      "getirUcret",
      "teslimatTip",
      "bolgelerDurum",
      "magazaDurum",
      "planlamaDurum",
      "alisverisTamam",
      "enAzSipasir",
      "odemeDurumlar"
    ]),
    ...get("cart", ["cartUrunler", "cartToplam"]),
    ...get("shop", ["urunler", "kisisellestirmeler", "date", "time"]),
    genislikHesap() {
      let deger;
      if (this.alisverisTamamlaDurum == 1) {
        deger = "650px";
      } else {
        deger = "500px";
      }
      return deger;
    },
    cartMiktar() {
      return _.sumBy(this.cartUrunler, "miktar");
    }
  },
  methods: {
    alisverisDurumKontrol() {
      let ad = false;
      let soyad = false;
      let mail = false;
      let tel = false;
      let adres = false;
      let date = false;
      let time = false;
      this.musteriBilgi.ad ? (ad = true) : (ad = false);
      this.musteriBilgi.soyad ? (soyad = true) : (soyad = false);
      this.musteriBilgi.mail == ""
        ? true
        : this.reg.test(this.musteriBilgi.mail)
        ? (mail = true)
        : (mail = false);
      this.musteriBilgi.tel ? (tel = true) : (tel = false);
      this.musteriBilgi.adres ? (adres = true) : (adres = false);
      this.date ? (date = true) : (date = false);
      this.time ? (time = true) : (time = false);
      if (this.teslimatTip == 0) {
        adres = true;
      }
      if (this.planlamaDurum == 1) {
        date = true;
        time = true;
      }
      return !_.every(
        [this.alisverisDurum, ad, soyad, tel, mail, adres, date, time],
        Boolean
      );
    },
    bolgeGetir(val = false) {
      let deger;
      if (!_.isString(val)) {
        deger = this.$t("mekandaTeslim");
        this.renk = "error";
      } else {
        deger = _.find(this.bolgeler[0], {
          object_key: val
        }).ad;
        this.renk = "secondary";
      }
      return deger;
    },
    bolgeDurum() {
      this.$store.set("app/bolgelerDurum", true);
    },
    sepetUrunBilgi(val, yer) {
      var urun = _.find(this.urunler[0], {
        object_key: val.id
      });
      return urun[yer];
    },
    sepetUrunKisisellestirme(val) {
      var fiyat = _.find(this.urunler[0], {
        object_key: val.id
      }).fiyat;
      var ogeler = val.kisisellestirme;
      var fiyatlar = [];

      _.forEach(ogeler, oge => {
        let aranan;
        let durdur = true;

        if (_.isObject(oge)) {
          if (oge.object_key) {
            aranan = oge.object_key;
          } else {
            durdur = false;
          }
        } else {
          aranan = oge;
        }

        if (durdur) {
          fiyatlar.push(
            _.find(this.kisisellestirmeler[0], {
              object_key: aranan
            }).ilaveFiyat
          );
        } else {
          fiyatlar.push(oge.ilaveFiyat);
        }
      });

      var toplamFiyat = _.reduce(
        fiyatlar,
        function(sum, n) {
          return sum + n;
        },
        fiyat
      );
      return toplamFiyat;
    },
    kisisellestirmeBilgi(val, yer) {
      let aranan;
      let durdur = true;
      let kisisellestirme;
      if (_.isObject(val)) {
        if (val.object_key) {
          aranan = val.object_key;
        } else {
          durdur = false;
        }
      } else {
        aranan = val;
      }
      if (durdur) {
        kisisellestirme = _.find(this.kisisellestirmeler[0], {
          object_key: aranan
        });
      } else {
        kisisellestirme = val;
      }

      return kisisellestirme[yer];
    },
    azalt(val) {
      this.$store.set("cart/azaltUrun!", val).then(() => {
        if (this.cartToplam == 0) {
          this.$store.set("app/alisverisTamamla", false);
          this.$store.set("app/sepetGoruntuleme", false);
        }
      });
    },
    arttir(val) {
      this.$store.set("cart/UrunlerArttir", val);
    },
    sonlandir() {
      var send = fonksiyon.httpsCallable("sendMail");
      send({
        from: "Beefreund, <service@beefreund.de>",
        to: this.musteriBilgi.mail,
        subject: this.profil.sirket,
        text: this.$t("epostaYazi")
      });
      this.$store.set("cart/cartUrunler", []);
      this.$store.set("cart/cartToplam", 0);
      this.$store.set("shop/kisisellestirmeDurum", false);
      this.$store.set("app/alisverisTamamla", false);
      this.$store.set("app/alisverisTamamlaDurum", 1);
      this.$store.set("app/sepetGoruntuleme", false);
      gsap.to(window, {
        duration: 1,
        scrollTo: 0,
        onComplete: () => {
          setTimeout(() => {
            this.$store.set("app/alisverisTamam", true);
          }, 100);
          setTimeout(() => {
            location.reload();
          }, 3000);
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.$cookies.get("cerezKabul")) {
          this.$cookies.set("cerezMusteriBilgi", this.musteriBilgi);
        }
        const alisverisTutar = this.cartToplam + Number(this.getirUcret);
        let siparis = {};
        if (this.musteriDurum == false) {
          veri
            .ref("musteriler")
            .orderByChild("mail")
            .equalTo(this.musteriBilgi.mail)
            .on("value", event => {
              if (event.exists()) {
                var gerceklesen = [];
                _.forEach(this.cartUrunler, urun => {
                  var kisisellestirmeler = [];
                  if (_.isObject(urun.kisisellestirme)) {
                    _.forEach(urun.kisisellestirme, kisisellestirme => {
                      kisisellestirmeler.push({
                        ad: this.kisisellestirmeBilgi(kisisellestirme, "ad"),
                        ilaveFiyat: this.kisisellestirmeBilgi(
                          kisisellestirme,
                          "ilaveFiyat"
                        )
                      });
                    });
                  }
                  gerceklesen.push({
                    ad: this.sepetUrunBilgi(urun, "ad"),
                    aciklama: this.sepetUrunBilgi(urun, "aciklama"),
                    file: this.sepetUrunBilgi(urun, "file")
                      ? this.sepetUrunBilgi(urun, "file")
                      : "",
                    fiyat: this.sepetUrunBilgi(urun, "fiyat"),
                    miktar: urun.miktar,
                    notDurum: urun.notDurum,
                    not: urun.not,
                    kisisellestirmeler
                  });
                });
                siparis = {
                  musteriID: O2A(event)[0].object_key,
                  musteriAd: O2A(event)[0].ad,
                  musteriSoyad: O2A(event)[0].soyad,
                  musteriTel: O2A(event)[0].tel,
                  musteriAdres: O2A(event)[0].adres,
                  musteriBolge: this.bolgeGetir(this.bolge),
                  durum: 0,
                  durumYazi: "Beklemede",
                  tarih: Date.now(),
                  odemeTip: this.odemeTip,
                  teslimatTip: this.teslimatTip,
                  planlama: this.planlamaDurum,
                  date: this.date,
                  time: this.time,
                  tarihYazi: moment(Date.now()).format("YYYY-MM-DD HH:mm"),
                  odemeTipYazi: this.odemeDurumlar[this.odemeTip - 1],
                  tutar: alisverisTutar,
                  gerceklesen,
                  siparisNot: this.siparisNot,
                  urunler: this.cartUrunler
                };
                veri
                  .ref("siparisler/")
                  .push(siparis)
                  .then(() => {
                    this.sonlandir();
                  });
              } else {
                this.musteriBilgi.bolge = this.bolge;
                this.musteriBilgi.kayitTarih = Date.now();
                this.musteriBilgi.reklam = this.checkbox;
                veri.ref("musteriler/").push(this.musteriBilgi);
              }
            });
        } else {
          var gerceklesen = [];
          _.forEach(this.cartUrunler, urun => {
            var kisisellestirmeler = [];
            if (_.isObject(urun.kisisellestirme)) {
              _.forEach(urun.kisisellestirme, kisisellestirme => {
                kisisellestirmeler.push({
                  ad: this.kisisellestirmeBilgi(kisisellestirme, "ad"),
                  ilaveFiyat: this.kisisellestirmeBilgi(
                    kisisellestirme,
                    "ilaveFiyat"
                  )
                });
              });
            }
            gerceklesen.push({
              ad: this.sepetUrunBilgi(urun, "ad"),
              aciklama: this.sepetUrunBilgi(urun, "aciklama"),
              file: this.sepetUrunBilgi(urun, "file")
                ? this.sepetUrunBilgi(urun, "file")
                : "",
              fiyat: this.sepetUrunBilgi(urun, "fiyat"),
              miktar: urun.miktar,
              notDurum: urun.notDurum,
              not: urun.not,
              kisisellestirmeler
            });
          });
          siparis = {
            musteriID: this.musteri.object_key,
            musteriAd: this.musteri.ad,
            musteriSoyad: this.musteri.soyad,
            musteriTel: this.musteri.tel,
            musteriAdres: this.musteri.adres,
            musteriBolge: this.bolgeGetir(this.bolge),
            durum: 0,
            tarih: Date.now(),
            odemeTip: this.odemeTip,
            teslimatTip: this.teslimatTip,
            planlama: this.planlamaDurum,
            date: this.date,
            time: this.time,
            tarihYazi: moment(Date.now()).format("YYYY-MM-DD HH:mm"),
            odemeTipYazi: this.odemeDurumlar[this.odemeTip - 1],
            tutar: alisverisTutar,
            gerceklesen,
            siparisNot: this.siparisNot,
            urunler: this.cartUrunler
          };

          veri
            .ref("siparisler/")
            .push(siparis)
            .then(() => {
              this.sonlandir();
            });
        }
      } else {
        var elmnt = document.getElementById("scrollToOdeme");
        elmnt.scrollIntoView();
      }
    }
  }
};
</script>

<style lang="scss">
.v-stepper__step__step {
  display: none !important;
}
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
  -webkit-background-clip: text;
}
input:-webkit-autofill {
  -webkit-text-fill-color: black !important;
}
.v-application--is-ltr .v-input__prepend-outer {
  margin-right: 40px;
}

.v-stepper__label {
  width: 100%;
}
.v-list-item {
  min-height: auto !important;
}
.ozel-checkbox {
  .v-input__slot {
    align-items: flex-start;
  }
}
</style>
