import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyBtKskF61KZm3c1qwam-D1_ezrfkPyYvkk",
  authDomain: "shawarma-beefreund-36af8.firebaseapp.com",
  databaseURL: "https://shawarma-beefreund-36af8-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "shawarma-beefreund-36af8",
  storageBucket: "shawarma-beefreund-36af8.appspot.com",
  messagingSenderId: "279751007760",
  appId: "1:279751007760:web:236ceec590289145e77495",
  measurementId: "G-WY3BQ0534Z"
};

const fb = firebase.initializeApp(firebaseConfig)
const veri = fb.database()
const fonksiyon = fb.functions()
const depo = fb.storage()

firebase.analytics();

export {
  fb,
  veri,
  fonksiyon,
  depo,
}
